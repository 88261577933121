import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Toolbar, List, Box, Typography, Divider, IconButton, Avatar, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../store/AuthContext';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: { width: `calc(${theme.spacing(8)} + 1px)` },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && { ...openedMixin(theme), '& .MuiDrawer-paper': openedMixin(theme) }),
        ...(!open && { ...closedMixin(theme), '& .MuiDrawer-paper': closedMixin(theme), }),
    }),
);

const DashboardLayout = (props) => {
    const theme = useTheme();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const authCtx = useContext(AuthContext);

    const navItemList = [
        { label: "Dashboard", icon: <DashboardIcon />, link: "/dashboard" },
        { label: "Organizations", icon: <Groups3OutlinedIcon />, link: "/organization" },
        // { label: "Users", icon: <GroupOutlinedIcon />, link: "/users" },
        { label: "Logout", icon: <LogoutIcon /> },
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar className='relative'>
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpen(true)} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }}>
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div"> Milton Admin </Typography>
                    <div className="h-10 w-10 p-4 absolute right-10 bg-gray-300 font-medium  rounded-full text-black flex items-center justify-center text-xl"> {"A"}</div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={() => setOpen(false)}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {navItemList.map(({ label, icon, link }, index) => (
                        <ListItem disablePadding key={index} className={`${link == pathname ? '!bg-[#408999] !text-white' : '!text-[#36454F]'}`} onClick={() => label == "Logout" ? authCtx.logout() : navigate(link)}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center',color: link === pathname ? '#fff' : '#36454F' }}>{icon}</ListItemIcon>
                                <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <DrawerHeader />
                {props.children}
            </Box>
        </Box>
    );
}

export default DashboardLayout;