import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    refresh: false,
}


const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setRefresh: (state, action) => {
            state.refresh = !state.refresh;
        }
    }
})

export  default messageSlice
export const messageAction = messageSlice.actions;