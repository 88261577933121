import { Fragment, Suspense, useContext } from "react";
import DashboardLayout from './layout/dashboardLayout';
import { ALL_LINKS } from './constant/routes';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthContext from "./store/AuthContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const authCtx = useContext(AuthContext);

  const PAGES = [
    ALL_LINKS.LOGIN,
    ALL_LINKS.FORGOT_PASSWORD,
    ALL_LINKS.DASHBOARD,
    ALL_LINKS.ORGANIZATION,
    ALL_LINKS.USERS,
    ALL_LINKS.USERS_DETAILS,
    ALL_LINKS.ORG_MESSAGES,
  ]

  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={'Loading...'}>
          <Routes>
            {PAGES.map((item, i) => {
              return ((item.loginRequired) ? (
                <Route key={i} path={item.pageLink} element={authCtx.isLoggedIn ? <DashboardLayout><item.view /></DashboardLayout> : <Navigate to={ALL_LINKS.LOGIN.pageLink} />} />
              ) :
                <Route key={i} path={item.pageLink} element={<item.view />} />)
            })}
          </Routes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </Fragment>
  )
}

export default App;