import { lazy } from "react";

const login =lazy(()=>import('../pages/auth/logIn/login'));
const forgotPassword =lazy(()=>import('../pages/auth/forgotPassword/forgotPassword'));
const Dashboard =lazy(()=>import('../pages/dashboard'));
const OrganizationDashboard =lazy(()=>import('../pages/organizations'));
const UsersDashboard =lazy(()=>import('../pages/users'));
const UsersDetails =lazy(()=>import('../pages/userDetail'));
const OrgMessage =lazy(()=>import('../pages/organizations/components/Messages'));

export const ALL_LINKS={
    LOGIN:{
        pageLink:'/',
        loginRequired:false,
        view:login
    },
    FORGOT_PASSWORD:{
        pageLink:'/forgot-password',
        loginRequired:false,
        view:forgotPassword
    },
    DASHBOARD:{
        pageLink:'/dashboard',
        loginRequired:true,
        view:Dashboard
    },
    ORGANIZATION:{
        pageLink:'/organization',
        loginRequired:true,
        view:OrganizationDashboard
    },
    USERS:{
        pageLink:'/organization/users',
        loginRequired:true,
        view:UsersDashboard
    },
    USERS_DETAILS:{
        pageLink:'/user-detail',
        loginRequired:true,
        view:UsersDetails
    },
    ORG_MESSAGES:{
        pageLink:'/organization/messages',
        loginRequired:true,
        view:OrgMessage
    },
}