import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    refresh: false,
}


const orgSlice = createSlice({
    name: 'org',
    initialState,
    reducers: {
        setRefresh: (state, action) => {
            state.refresh = !state.refresh;
        }
    }
})

export  default orgSlice
export const orgActions = orgSlice.actions;